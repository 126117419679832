// i18next-extract-mark-ns-start presse-page
import React from "react"
import { JesLayout } from "../components/jes-layout"
import {
  TierCol,
  JesVerticalSpacer,
  JesRow,
  FullCol,
} from "../components/layout"
import { PDFImagePreview } from "../components/pdf-preview"
import { graphql } from "gatsby"

const PressePage = ({}) => {
  return (
    <JesLayout title="Presse">
      <JesRow noPadding noGutter>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/230323.png"
            url="/230323.pdf"
            meta={{
              date: "2023.03.23",
              title: "Gemeinde Utting, Bürgerbrief",
              description:
                "JES Kulturstiftung belebt die Künstlerkolonie Holzhausen",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/230922.png"
            url="/230922.pdf"
            meta={{
              date: "2023.09.22",
              title: "Ammerseekurier",
              description:
                "Malerin Hansl Bock zählt zur „verschollenen Generation“",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/220325.png"
            url="/220325.pdf"
            meta={{
              date: "2022.03.25",
              title: "Ammerseekurier",
              description: "Kurt Kühn bezieht das Haus Nr. 33",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/220315.png"
            url="/220315.pdf"
            meta={{
              date: "2022.03.15",
              title: "Ammerseekurier",
              description: "Ein Grieche in Holzhausen",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/220906.png"
            url="/220906.pdf"
            meta={{
              date: "2022.09.06",
              title: "Ammerseekurier",
              description: "155. Geburtstag des Malers Franz Wilhelm Voigt",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/220823.png"
            url="/220823.pdf"
            meta={{
              date: "2022.08.23",
              title: "Ammerseekurier",
              description: "Malwine Georgi - ihr Leben endet im KZ",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/220225.png"
            url="/220225.pdf"
            meta={{
              date: "2022.02.25",
              title: "Ammerseekurier",
              description: "Blumenmalerin wäre 145 Jahre alt geworden",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/211213.png"
            url="/211213.pdf"
            meta={{
              date: "2021.12.13",
              title: "Exklusive Veröffentlichung",
              description:
                "Artikel für die Chronik „900 Jahre Utting“, verfasst von Mitarbeitern der Stiftung",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/211109.png"
            url="/211109.pdf"
            meta={{
              date: "2021.11.09",
              title: "Landsberger Tagblatt",
              description: "Ein Pionier der Künste am Ammersee",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210814.png"
            url="/210814.pdf"
            meta={{
              date: "2021.08.14",
              title: "Landsberger Tagblatt",
              description: "Auf den Spuren von Ludwig Bock",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210716.png"
            url="/210716.pdf"
            meta={{
              date: "2021.07.16",
              title: "Ammerseekurier",
              description: "Erinnerung an eine namhafte Opernsängerin",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210521.png"
            url="/210521.pdf"
            meta={{
              date: "2021.05.21",
              title: "Ammerseekurier",
              description: "Sammler und ein gefragter Galerist",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210409.jpg"
            url="/210409.pdf"
            meta={{
              date: "2021.04.09",
              title: "Landsberger Tagblatt",
              description:
                "Mitbegründer der „Scholle“ und gefragter Künstler seiner Zeit",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210407.jpg"
            url="/210407.pdf"
            meta={{
              date: "2021.04.07",
              title: "Landsberger Tagblatt",
              description: "Der „Simplicissimus“ feiert 125. Geburtstag",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210209.jpg"
            url="/210209.pdf"
            meta={{
              date: "2021.02.09",
              title: "Ammerseekurier",
              description:
                "Ein herausragender Karikaturist hätte heute Geburtstag",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210209-1.png"
            url="/210209-1.pdf"
            meta={{
              date: "2021.02.09",
              title: "Landsberger Tagblatt",
              description: "Eduard Thöny zum 155. Geburstag",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/210105.jpg"
            url="/210105.pdf"
            meta={{
              date: "2021.01.05",
              title: "Kreisbote Landsberg",
              description: "Per App auf den Spuren der „Scholle“",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/201216.png"
            url="/201216.pdf"
            meta={{
              date: "2020.12.16",
              title: "Landsberger Tagblatt",
              description: "Erich Erler zum 150. Geburtstag",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/201212.jpg"
            url="/201212.pdf"
            meta={{
              date: "2020.12.12 ",
              title: "Landsberger Tagblatt",
              description: "Eine Stiftung für die Holzhauser Kunst",
            }}
          />
        </TierCol>
        <TierCol>
          <PDFImagePreview
            noPreview
            cover="/201211.jpg"
            url="/201211.pdf"
            meta={{
              date: "2020.12.11",
              title: "Ammersee Kurier",
              description: "Eine Stiftung für die Künstlerkolonie",
            }}
          />
        </TierCol>
      </JesRow>
    </JesLayout>
  )
}

export default PressePage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
