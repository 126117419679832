import React, { useState, useEffect } from "react"
// import { Document, Page, pdfjs } from "react-pdf"
import { Spin } from "antd"
import { StandardParagraph, BLOCKS_SHADOW, Bold } from "./blocks"
import { TierCol } from "./layout"
// import "./pdf.css"

interface DocumentData {
  date: string
  title: string
  description
}

interface PDFImagePreviewProps {
  meta: DocumentData
  url?: string
  cover?: string
  noPreview?: boolean
}
export const PDFImagePreview: React.FC<PDFImagePreviewProps> = ({
  url = "/test.pdf",
  meta,
  cover,
  noPreview,
}) => {
  // useEffect(() => {
  //   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  // }, [])

  return (
    <a
      style={{
        display: "flex",
        flexDirection: "row",
        textDecoration: "none",
        color: "inherit",
        width: "100%",
        paddingBottom: "70px",
      }}
      href={url}
      target="_blank"
    >
      <div
        className="main"
        style={{
          width: "100px",
          // height: "141px",
          flexBasis: "25%",
          overflow: "hidden",
        }}
      >
        {/* {noPreview ? ( */}
        <img
          style={{ width: "100%", boxShadow: BLOCKS_SHADOW }}
          src={cover}
          alt={meta.title}
        />
        {/* // ) : (
        //   <Document file={url} loading={<Spin spinning />}>
        //     <Page pageNumber={1} width={100} loading={<Spin spinning />} />
        //   </Document>
        // )} */}
      </div>
      <StandardParagraph style={{ padding: "0 25px", flex: 1 }}>
        <StandardParagraph style={{ marginBottom: 0 }}>
          {meta.date}
        </StandardParagraph>
        <StandardParagraph>{meta.title}</StandardParagraph>
        <StandardParagraph>
          <Bold>{meta.description}</Bold>
        </StandardParagraph>
      </StandardParagraph>
    </a>
  )
}
